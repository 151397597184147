<template>
  <div>
    <leaflet-panel
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :show-detail-btn="showDetailBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`产品服务`" :en_title="`Product Service`"></Paragraph>

    <Logotitlebox :datalist="datalistx2"></Logotitlebox>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`产品能力`" :en_title="`Product Capabilities`"></Paragraph>
    <div style="width: 70%;margin-left: 15%">
    <Logotitlebox :datalist="datalistx3"></Logotitlebox>
    </div>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`产品特点`" :en_title="`Features`"></Paragraph>

    <Titlenewlinelist :datalist="datalist" style="margin-bottom: 6rem"></Titlenewlinelist>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`应用场景`" :en_title="`Application Scenarios`"></Paragraph>

    <div style="display: flex;flex-direction: row;margin-left: 10%;margin-right: 10%">

      <Lettertitleblock :datalist="datalistx"
                        style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblock>
    </div>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';
import Logotitlebox from '@/components/chain/basic/common/logotitlebox';
import Titlenewlinelist from '@/components/chain/basic/common/titlenewlinelist';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Lettertitleblock,
    Logotitlebox,
    Titlenewlinelist,
  },
  data() {
    return {
      datalist: [
        { title: '安全合规：数据安全性更高', content: '原始数据不出域，数据输入、运算、结果输出全流程密态保护。数据经授权后方可进行安全计算，最小化利用，数据调用可追溯审计。产品得到信通院、公安部等保等多个安全与隐私保护认证' },
        { title: '一站式服务：业务应用更快速', content: '与多家优质合规大数据公司、媒体等伙伴达成合作，客户可一站式完成多方数据及业务合作，大大降低客户时间成本。从数据测试、模型构建、完成投放和风控，最快只需一星期。' },
        { title: '图形化界面：操作门槛更低', content: '操作便捷，使用门槛低，简单高效地完成建模、统计、服务部署任务。没有算法和开发经验的人也能快速上手使用。' },
        { title: '多样化部署：部署更轻量便捷', content: 'Docker化部署，支持多种操作系统，支持主流数据源和数据服务，支持云及本地部署，机构可灵活投入硬件与计算资源。' },
      ],
      datalistx3: [
        {
          imageurl: require('@/assets/image/contentsvg/gas.svg'),
          title: '安全模型',
          list: [
            '保护数据安全和模型',
            '较单方数据建模预测能力提升',
            '20%~50%',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/car.svg'),
          title: '安全策略',
          list: [
            '远程测试、部署分布式策略',
            '保证数据安全，提升风控能力',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/note.svg'),
          title: '安全统计',
          list: [
            '实现分布式安全数据开发',
            '报表等功能，便捷高效安全实现',
            '跨机构联合统计',
          ],
        },
      ],
      datalistx2: [
        {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          title: '技术实力领先',
          list: [
            '70多项相关专利（全国第一）',
            '性能超业内算法3~100倍',
            'iDASH2019隐私计算比赛全球冠军',
            '&nbsp;',
            '&nbsp;',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/fly.svg'),
          title: '引领商业实践',
          list: [
            '率先在金融、电信、汽车等',
            '10多个行业中完成商用',
            '支持上百家企业线上系统运行',
            '能够支撑实际生产环境下的',
            '复杂数据安全计算任务',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/setting.svg'),
          title: '商业生态开放',
          list: [
            '全球首个可信联合计算商业联盟',
            '创始成员，技术、产品、生态等',
            '资源和能力共享',
            '&nbsp;',
            '&nbsp;',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/star.svg'),
          title: '权威安全认证',
          list: [
            '首家通过中国信通院',
            '多方安全计算标准认证',
            '拥有公安部等保、国家信息技术安全',
            '研究中心、TRUSTe隐私保护等',
            '多项国内外权威认证',
          ],
        },
      ],
      datalistx: [
        {
          title: '联合营销',
          imgsrc: 'A',
          list: [
            '金融、电信、汽车等行业机构基于',
            '摩斯多方安全计算平台',
            '能够安全高效地与第三方数据机构',
            '进行数据合作',
            '低成本建立客群画像、预测模型',
            '精准定位目标客群',
            '并通过优质媒体投放提升业务转化',
          ],
        }, {
          title: '联合风控',
          imgsrc: 'B',
          list: [
            '在保障各金融机构不泄露数据',
            '安全合规的前提下，进行数据合作',
            '联防联控，提升通过率，降低坏账率',
            '有效减低金融风险',
          ],
        },
      ],
      title: '隐私计算服务',
      body:
          '大规模多方安全计算商用平台，基于多方安全计算、隐私保护、区块链等技术，实现数据可用不可见，'
          + '解决企业数据协同计算过程中的数据安全和隐私保护问题，助力机构安全高效地完成联合风控、联合营销、联合科研等跨机构数据合作任务，驱动业务增长。',
      // TODO: REPLACE WITH PRIVACY PANEL IMAGE
      image: require('@/assets/image/chain/va/privacy/privacy.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/va/privacy/6_01.jpg'),
        require('@/assets/image/chain/va/privacy/6_02.jpg'),
        require('@/assets/image/chain/va/privacy/6_03.jpg'),
        require('@/assets/image/chain/va/privacy/6_04.jpg'),
      ],
    };
  },
};
</script>

<style scoped></style>
